import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../../helper";
import HtmlParser from "react-html-parser";

function PermierTech({data}) {
    return (
        <section className="permier_tech p-100">
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div className="example" data-text="Solution">
                            <h2>{checkData(data, 'premier_tech_title')}</h2>
                        </div>
                        <div>{HtmlParser(checkData(data, 'premier_tech_description'))}</div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        {data?.premier_tech_tabs?.map((value, i) => (
                            <div>{HtmlParser(checkData(value, 'description'))}</div>
                        ))}

                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default PermierTech;