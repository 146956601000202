import React, {useEffect, useState} from 'react';
import Seo from "../../layout/seo";
import {checkData} from "../../../helper";
import Layout from "../../layout/layout";
import Loader from "../../components/Loader";
import Banner from "./banner";
import Breadcrumbs from "../../components/breadcrumbs";
import axios from "axios";
import {baseURL} from "../../../utils/constant";
import LeadingServices from "./leading_services";
import ExpertSoftware from "./expert_software";
import Awardsrecognitions from "../../home/awardsrecognitions";
import ExpertDeveloper from "./expert_developer";
import PermierTech from "./permier_tech";
import Locationpath from "./locationpath";
import Leadingsoftware from "./leadingsoftware";
import Featured from "../../home/featured";
import Question from "../../services/question";
import Partner from "../../home/partner";

function Index({slug}) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [userData, setUserData] = useState([]);
    const getGiHubUserWithAxios = async () => {
        setIsLoaded(true);
        const response = await axios.get(`${baseURL}/location/${slug}`);
        setUserData(response.data.data);
        setIsLoaded(false);
    };
    useEffect(() => {
        getGiHubUserWithAxios();
    }, [slug]);


    return (
        <Layout>
            {isLoaded ?
                <Loader/>
                :
                <>
                    <Seo title={checkData(userData, 'meta_title')}
                         description={checkData(userData, 'meta_description')}/>
                    <Breadcrumbs link1="TekRevol" link2="Locations" link3={userData.name}/>
                    <Banner data={userData}/>
                    <LeadingServices data={userData}/>
                    <ExpertSoftware data={userData}/>
                    <Awardsrecognitions bg="bg"/>
                    <ExpertDeveloper data={userData}/>
                    <PermierTech data={userData}/>
                    <Locationpath data={userData}/>
                    <Featured/>
                    <Leadingsoftware data={userData}/>
                    <Question data={userData}/>
                    <Partner/>
                </>
            }
        </Layout>
    );
}

export default Index;