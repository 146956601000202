import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../../helper";
import Link from "gatsby-link";
import HtmlParser from "react-html-parser";

function ExpertSoftware({data}) {
    return (
        <section className="expert_software p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div className="example" data-text="Trasformation">
                            <h2>{checkData(data, 'expert_company_title')}</h2>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="my-auto">
                        <div>{HtmlParser(checkData(data, 'expert_company_description'))}</div>
                        <div className="btn_talk">
                            <Link to={checkData(data, 'expert_company_btn_url')}
                                  className="border_btn">{checkData(data, 'expert_company_btn_txt')}</Link>
                        </div>
                    </Col>
                </Row>

            </Container>

        </section>
    );
}

export default ExpertSoftware;