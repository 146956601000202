import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {mediaBaseURL} from "../../../utils/constant";
import {checkData} from "../../../helper";
import HtmlParser from "react-html-parser";

function Locationpath({data}) {
    return (
        <section className="location_path p-100"
                 style={{backgroundImage: `url(${mediaBaseURL}${checkData(data, 'location_name_image')})`}}>
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={10} lg={10} xl={10} xxl={10} className="text-center mx-auto">
                        <div className="location_div">
                            <h2>{checkData(data, 'location_name_title')}</h2>
                            <p>{HtmlParser(checkData(data, 'location_name_subtitle'))}</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Locationpath;